import { postRequest, getRequest } from './index'
// 结算
// 列表接口分页
export const agentSettleInfoQueryByPage = (data, successCallback, failureCallback) => {
  postRequest('/agentSettle/queryByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 开票列表接口分页
export const invoiceIssureQueryByPage = (data, successCallback, failureCallback) => {
  postRequest('/invoiceIssure/queryByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 采购合同开票提交时查看票样
export const agentPurchaseContractViewInvoiceSample = (id, successCallback, failureCallback) => {
  getRequest(`/agentPurchaseContract/viewInvoiceSample/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 采购合同发票提交
export const agentPurchaseContractInvoiceSubmit = (data, successCallback, failureCallback) => {
  postRequest(`/agentPurchaseContract/invoiceSubmit`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 发票详情
export const invoiceIssureInvoiceDetail = (data, successCallback, failureCallback) => {
  postRequest('/invoiceIssure/invoiceDetail', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 结算详情
export const agentSettleInfoQueryByDetail = (id, successCallback, failureCallback) => {
  getRequest(`/agentSettle/queryByDetail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 结算付款
export const agentSettleSettlePayment = (data, successCallback, failureCallback) => {
  postRequest('agentSettle/settlePayment', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取结算付款/收款链接
export const agentSettleGetSettleAbcPayUrl = (data, successCallback, failureCallback) => {
  postRequest('agentSettle/getSettleAbcPayUrl', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 批量支付的链接
export const agentSettleBatchSettlePayment = (data, successCallback, failureCallback) => {
  postRequest('agentSettle/batchSettlePayment', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取打印结算单的详情
export const agentSettlePrintSettleDocData = (id, successCallback, failureCallback) => {
  getRequest(`/agentSettle/printSettleDocData/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取垫付记录
export const agentSettlePayAdvanceDetailSettle = (contractId, successCallback, failureCallback) => {
  getRequest(`/agentSettle/payAdvanceDetailSettle/${contractId}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
